@import '@aurora/shared-client/styles/_variables.pcss';

.lia-build-wrap {
  grid-area: build;
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: 5px;
  color: var(--lia-bs-gray-600);
  font-size: var(--lia-font-size-xxs);
  gap: 10px;

  @media (--lia-breakpoint-down-sm) {
    margin-top: 15px;
  }

  .lia-build-link {
    color: var(--lia-bs-gray-600);
    text-decoration: underline;
  }
}
